import React, { useState, useEffect, useRef } from 'react';
import "@fontsource/roboto-serif/300.css";
import '../styles/master.scss';
import '../styles/index/index.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { gsap } from 'gsap';
import { Helmet } from "react-helmet";

import muteIcon from '../images/mute.svg';
import kindGardenIcon from '../images/kindgarden.png';
import blackBird from '../images/black_bird.svg';
import blueBird from '../images/blue_bird.svg';
import musicNote from '../images/music_note.svg';
import musicNote2 from '../images/music_note_2.svg';

const Index = () => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const today = new Date().getDay();

  const [muted, setMuted] = useState(true);
  const audioRef = useRef();

  const unMute = () => {
    setMuted(false);
    audioRef.current.play();
  }

  const birdVariants = {
    initial: {
      opacity: 0,
      x: -100,
      y: -100,
      rotate: 5,
    },
    animate: muted ? '' : {
      opacity: 1,
      x: 0,
      y: 10,
      rotate: -10,
      transition: {
        duration: 1.2,
        delay: 0.5,
        ease: 'easeOut',
      }
    },
  }

  const copyVariants = {
    initial: {
      opacity: 0,
      y: 50,
      x: -20,
      scale: 0.95,
    },
    animate: muted ? '' : {
      opacity: 1,
      scale: 1,
      y: 0,
      x: 0,
      transition: {
        duration: 1,
        delay: 2,
        ease: "circOut",
      }
    },
  }

  useEffect(() => {
    if (muted) return;

    gsap.to(".garden", {
      "--gray-stop": 500,
      duration: 5,
      delay: 0.8,
      ease: "power4.inOut",
    });
  }, [muted]);

  return (
    <div>
      <Helmet>
        <title>Kind Garden</title>
        <meta name="description" content="Welcome to Kind Garden!" />
        <meta itemprop="name" content="Kind Garden" />
        <meta itemprop="description" content="Welcome to Kind Garden!" />
        <meta itemprop="image" content="https://kindgarden.org/images/social/default.png" />
        <meta property="og:url" content="https://kindgarden.org" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Kind Garden" />
        <meta property="og:description" content="Welcome to Kind Garden!" />
        <meta property="og:image" content="https://kindgarden.org/images/social/default.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Kind Garden" />
        <meta name="twitter:description" content="Welcome to Kind Garden!" />
        <meta name="twitter:image" content="https://kindgarden.org/images/social/twitter.png" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>


      {!muted && (
        <motion.div className="copy-wrapper"
          initial="initial"
          animate="animate"
          variants={copyVariants}
        >
          <div className="copy">
            <h1>Welcome to Kind&nbsp;Garden</h1>

            <p>It is nice to have you here on a&nbsp;{days[today]}.</p>

            <p>Hello! I am <a href="https://akshardave.com?r=KindGarden" target="_blank" rel="noreferrer">Akshar Dave</a>. The sounds you hear are from a bird responding to its own voice. I recorded it singing, then played it back to the bird. It&nbsp;responded! <img src={kindGardenIcon} alt="" className='kind-garden-icon' draggable="false" loading="eager" /> </p>
          </div>
        </motion.div>
      )}

      <div className="kind-garden">
        <div className="music-notes">
          <img className='note note-1' src={musicNote} alt="" title="tweet toot" draggable="false" loading="eager" />
          <img className='note note-2' src={musicNote2} alt="" title="toot tweet" draggable="false" loading="eager" />
        </div>

        <div className="birds">
          <motion.div className="blue-bird"
            initial="initial"
            animate="animate"
            variants={birdVariants}
          >
            <img src={blueBird} alt="" title='🪞' draggable="false" loading="eager" />
          </motion.div>
          <div className="black-bird">
            <img src={blackBird} alt="" title='Hello! 🎶🎵💚' draggable="false" loading="eager" />
          </div>
        </div>
        <div className="garden-wrapper">
          <div className="garden">
            <AnimatePresence>
              {muted && (
                <motion.button
                  className='unmute-btn'
                  onClick={unMute}
                  title="Hear the bird!"
                  initial={{ opacity: 0, width: 50, scale: 0.5 }}
                  animate={{ opacity: 1, width: 150, scale: 1 }}
                  exit={{ scale: 0.75, opacity: 0, transition: { duration: 0.2, ease: 'circOut' } }}
                  whileTap={{ scale: 0.75 }}
                  transition={{ ease: [0, -0.01, 0, 1.31], duration: 0.6 }}
                >
                  <img src={muteIcon} alt="Unmute" draggable="false" />
                  <span>here, hear</span>
                </motion.button>
              )}
            </AnimatePresence>
            <audio src="/music/evening.mp3" autoPlay loop playsInline muted={muted} ref={audioRef}></audio>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
